<template>
    <div class="row justify-content-center">
        <b-col cols="12">
            <div class="header">
                <div class="container-fluid">
                    <!-- Body -->
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    {{ $t("intercom.title") }}
                                </h6>
                                <h1 class="header-title">
                                    {{ $t("intercom.staircases") }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-container fluid>
                <b-row>
                    <b-col cols="12">
                        <div
                            class="card card-fill staircase-apartments border-secondary"
                        >
                            <div class="card-header">
                                <div class="col">
                                    <h4 class="card-header-title">
                                        {{ $t("intercom.apartments") }}
                                    </h4>
                                </div>
                                <div class="col">
                                    <form>
                                        <b-input-group
                                            size="sm"
                                            class="input-group-merge"
                                        >
                                            <b-form-input
                                                :placeholder="
                                                    $t('common.search')
                                                "
                                                v-model="
                                                    filterLocationApartments
                                                "
                                                class="form-control form-control-prepended list-search"
                                                type="search"
                                            ></b-form-input>

                                            <!-- Prepend -->
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <span
                                                        class="fe fe-search"
                                                    ></span>
                                                </div>
                                            </div>
                                        </b-input-group>
                                    </form>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-row>
                                    <b-col cols="12" xl="4" md="6">
                                        <div class="card bg-light">
                                            <div
                                                class="card-header"
                                                v-if="activeApartmentsId"
                                            >
                                                <div class="col">
                                                    <h4
                                                        class="card-header-title"
                                                    >
                                                        {{
                                                            $t(
                                                                "intercom.apartments_info"
                                                            )
                                                        }}
                                                    </h4>
                                                </div>
                                            </div>
                                            <div
                                                class="card-body"
                                                v-if="activeApartmentsId"
                                            >
                                                <b-card-text>
                                                    <h4
                                                        class="card-header-title"
                                                    >
                                                        {{
                                                            activeApartments.Name
                                                        }}
                                                    </h4>
                                                    <b-list-group>
                                                        <b-list-group-item
                                                            v-for="tenant in activeApartments.Tenants"
                                                            :key="tenant.Id"
                                                            >{{
                                                                tenant.Name
                                                            }}</b-list-group-item
                                                        >
                                                    </b-list-group>

                                                    <div
                                                        class="col"
                                                        v-if="
                                                            !activeApartments
                                                                .Tenants.length
                                                        "
                                                    >
                                                        {{
                                                            $t(
                                                                "intercom.no_tenants"
                                                            )
                                                        }}
                                                    </div>
                                                </b-card-text>
                                            </div>
                                            <div class="card-body" v-else>
                                                {{
                                                    $t(
                                                        "intercom.apartments_info"
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" xl="8" md="6">
                                        <div class="card">
                                            <div class="card-body">
                                                <b-row>
                                                    <apartment-small-item
                                                        v-for="apartment in locationApartmentsFiltered"
                                                        :apartment="apartment"
                                                        :active="
                                                            activeApartmentsId &&
                                                            activeApartmentsId ==
                                                                apartment.Id
                                                        "
                                                        :inStaircase="
                                                            apartment.Staircases.includes(
                                                                staircaseId
                                                            )
                                                        "
                                                        :key="apartment.Id"
                                                        @click.native="
                                                            showApartmentInfo(
                                                                apartment
                                                            )
                                                        "
                                                        @toStaircase="
                                                            toStaircase(
                                                                apartment
                                                            )
                                                        "
                                                    />
                                                </b-row>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col"></div>
                                    <div class="col-auto">
                                        <b-button
                                            variant="primary"
                                            size="sm"
                                            @click="addAllApartment"
                                            >{{
                                                $t("intercom.add_all_apartment")
                                            }}
                                        </b-button>
                                    </div>
                                    <div class="col-auto">
                                        <b-button
                                            variant="primary"
                                            size="sm"
                                            @click="createApartment"
                                            >{{
                                                $t("intercom.create_apartments")
                                            }}</b-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div
                            class="card card-fill staircase-apartments border-secondary"
                        >
                            <div class="card-header">
                                <div class="col">
                                    <h4 class="card-header-title">
                                        {{ $t("intercom.staircase") }}
                                    </h4>
                                </div>
                                <div class="col">
                                    <form>
                                        <b-input-group
                                            size="sm"
                                            class="input-group-merge"
                                        >
                                            <b-form-input
                                                :placeholder="
                                                    $t('common.search')
                                                "
                                                v-model="
                                                    filterStaircaseApartments
                                                "
                                                class="form-control form-control-prepended list-search"
                                                type="search"
                                            ></b-form-input>

                                            <!-- Prepend -->
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <span
                                                        class="fe fe-search"
                                                    ></span>
                                                </div>
                                            </div>
                                        </b-input-group>
                                    </form>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-row>
                                    <b-col cols="12" xl="4" md="6">
                                        <div class="card bg-light">
                                            <div class="card-header">
                                                <div class="col">
                                                    <h4
                                                        class="card-header-title"
                                                    >
                                                        {{
                                                            $t(
                                                                "intercom.staircase_doors"
                                                            )
                                                        }}
                                                    </h4>
                                                </div>
                                                <div class="col-auto">
                                                    <Select2
                                                        :options="
                                                            getUnassignedDoorsList
                                                        "
                                                        @select="addDoor"
                                                        :settings="{
                                                            placeholder: $t(
                                                                'doors.add'
                                                            ),
                                                            allowClear: true,
                                                            minimumResultsForSearch: 1,
                                                            containerCssClass:
                                                                'custom-select custom-select-sm form-control-flush',
                                                            dropdownCssClass:
                                                                'dropdown-menu dropdown-menu-sm',
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <b-row
                                                    v-if="
                                                        staircaseDevices.length
                                                    "
                                                >
                                                    <door-small-item
                                                        v-for="device in staircaseDevices"
                                                        :device="device"
                                                        :key="device.Device.Id"
                                                /></b-row>
                                                <b-row v-else>
                                                    <b-card-text>
                                                        {{
                                                            $t(
                                                                "intercom.no_doors"
                                                            )
                                                        }}
                                                    </b-card-text>
                                                </b-row>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" xl="8" md="6">
                                        <b-row>
                                            <staircase-apartment-small-item
                                                v-for="apartment in staircaseApartmentsFiltered"
                                                :apartment="apartment"
                                                @fromStaircase="
                                                    fromStaircase(apartment.Id)
                                                "
                                                :key="apartment.Id"
                                            />
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col"></div>
                                    <div class="col-auto">
                                        <b-button
                                            variant="primary"
                                            size="sm"
                                            @click="removeAllApartment"
                                            >{{
                                                $t(
                                                    "intercom.remove_all_apartment"
                                                )
                                            }}
                                        </b-button>
                                    </div>
                                    <div class="col-auto">
                                        <b-button
                                            variant="secondary"
                                            size="sm"
                                            @click="addAllApartment"
                                            >{{ $t("intercom.save_staircase") }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-col>
    </div>
</template>

<script>
import ApartmentSmallItem from "@/components/intercom/ApartmentSmallItem";
import DoorSmallItem from "@/components/intercom/DoorSmallItem";
import StaircaseApartmentSmallItem from "@/components/intercom/StaircaseApartmentSmallItem";
import Select2 from "@/components/common/Select2";

import { mapGetters } from "vuex";

import { INTERCOM_GET_LOCATION_APARTMENTS } from "@/store/types/intercom";
import { DEVICES_GET } from "@/store/types/devices";

export default {
    data() {
        return {
            filterLocationApartments: "",
            filterStaircaseApartments: "",
            showLoader: true,
            locationApartments: [],
            staircaseApartments: [],
            staircaseDevices: [],
            staircaseId: 1,
            activeApartmentsId: null,
            activeApartments: null,
        };
    },
    computed: {
        ...mapGetters([
            "getUser",
            "getLocationApartments",
            "getLocationDevices",
            "getActiveLocation",
        ]),
        locationApartmentsFiltered() {
            return this.locationApartments.filter((item) =>
                item.Name.toLowerCase().includes(
                    this.filterLocationApartments.toLowerCase()
                )
            );
        },
        staircaseApartmentsFiltered() {
            return this.staircaseApartments.filter((item) =>
                item.Name.toLowerCase().includes(
                    this.filterStaircaseApartments.toLowerCase()
                )
            );
        },
        getUnassignedDoorsList() {
            return this.getLocationDevices
                .filter((item) => {
                    return !this.staircaseDevices.find(
                        (door) => door.Device.Id == item.Device.Id
                    );
                })
                .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
                })
                .map((door) => {
                    return { id: door.Device.Id, text: door.Device.Name };
                });
        },
    },
    created() {
        this.$store
            .dispatch(
                INTERCOM_GET_LOCATION_APARTMENTS,
                this.$route.params.locationId
            )
            .then(() => {
                this.showLoader = false;
                this.locationApartments = this.getLocationApartments(
                    this.$route.params.locationId
                );
            });
        this.$store.dispatch(DEVICES_GET, this.$route.params.locationId);
    },
    methods: {
        createApartment() {},
        showApartmentInfo(apartment) {
            this.activeApartmentsId = apartment.Id;
            this.activeApartments = apartment;
        },
        toStaircase(apartment) {
            if (
                this.staircaseApartments.find((item) => item.Id == apartment.Id)
            ) {
                return false;
            }
            apartment.Staircases.push(this.staircaseId);
            this.staircaseApartments.push(apartment);
        },
        fromStaircase(apartmentId) {
            const index = this.staircaseApartments.findIndex(
                (item) => item.Id == apartmentId
            );
            const apartments = this.locationApartments.find(
                (item) => item.Id == apartmentId
            );
            apartments.Staircases = apartments.Staircases.filter(
                (item) => item != this.staircaseId
            );
            this.staircaseApartments.splice(index, 1);
        },
        addAllApartment() {
            this.locationApartmentsFiltered.forEach((item) => {
                this.toStaircase(item);
            });
        },
        removeAllApartment() {
            this.staircaseApartmentsFiltered.forEach((item) => {
                this.fromStaircase(item.Id);
            });
        },
        addDoor(door) {
            const doorItem = this.getLocationDevices.find(
                (item) => item.Device.Id == door.id
            );
            this.staircaseDevices.push(doorItem);
        },
        deleteDoor(doorId) {
            const index = this.staircaseDevices.findIndex(
                (item) => item.Device.Id == doorId
            );
            this.staircaseDevices.splice(index, 1);
        },
    },
    components: {
        ApartmentSmallItem,
        StaircaseApartmentSmallItem,
        Select2,
        DoorSmallItem,
    },
};
</script>
<style scoped>
.staircase-apartments {
    min-height: 200px;
}
</style>
