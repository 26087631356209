<template>
    <b-col cols="12" lg="4" md="3">
        <div class="card mb-1 apartments-card bg-light">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <!-- Avatar -->
                        <b-avatar
                            :badge="apartment.Tenants.length + ''"
                            variant="secondary"
                            badge-variant="primary"
                        ></b-avatar>
                    </div>
                    <div class="col ml-n2">
                        <!-- Title -->
                        <h4 class="mb-1">
                            <a href="#">{{ apartment.Name }}</a>
                        </h4>
                    </div>
                    <div class="col-auto">
                        <b-button
                            variant="light"
                            class="mb-2"
                            @click="$emit('fromStaircase')"
                        >
                            <b-icon
                                class="bg-warning"
                                icon="dash-square"
                                aria-label="Help"
                            ></b-icon>
                        </b-button>
                    </div>
                    <!-- <div class="col-auto">
                        <b-dropdown
                            variant="outline"
                            toggle-class="dropdown-ellipses pr-0"
                            no-caret
                            right
                            toggle-tag="a"
                        >
                            <template v-slot:button-content>
                                <i class="fe fe-more-vertical"></i>
                            </template>
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deleteUser()"
                                >{{ $t("buttons.delete") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                class="text-left text-truncate"
                                @click="deleteUser()"
                                >{{ $t("buttons.delete") }}</b-dropdown-item
                            >
                        </b-dropdown> 
                    </div>-->
                </div>
            </div>
        </div>
    </b-col>
</template>
<script>
export default {
    name: "staircase-apartment-small-item",
    props: ["apartment"],
    created() {},
    methods: {},
};
</script>
<style scoped>
.apartments-card .card-body {
    padding: 5px;
}
</style>
