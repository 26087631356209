<template>
    <div class="card mb-1 apartments-card col-12">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-auto">
                    <!-- Avatar -->
                    <img
                        :src="
                            device.Device.SmallImageUrl &&
                            device.Device.SmallImageUrl !=
                                'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                ? device.Device.SmallImageUrl
                                : '/images/zesec-placeholder.png'
                        "
                        alt="..."
                        class="avatar rounded"
                    />
                </div>
                <div class="col ml-n2">
                    <!-- Title -->
                    <h4 class="mb-1">
                        <a href="#">{{ device.Device.Name }}</a>
                    </h4>
                </div>
                <div class="col-auto">
                    <b-icon
                        v-if="intercom"
                        icon="camera-video"
                        variant="success"
                    ></b-icon>
                    <b-icon
                        v-else
                        icon="camera-video-off"
                        variant="danger"
                    ></b-icon>
                </div>
                <div class="col-auto">
                    <b-dropdown
                        variant="outline"
                        toggle-class="dropdown-ellipses pr-0"
                        no-caret
                        right
                        toggle-tag="a"
                    >
                        <template v-slot:button-content>
                            <!-- <i class="fe fe-more-vertical"></i> -->
                            <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                        </template>
                        <b-dropdown-item
                            class="text-left text-truncate"
                            @click="intercomEnable"
                            >{{ $t("intercom.enable") }}</b-dropdown-item
                        >
                    </b-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "door-small-item",
    data() {
        return {
            intercom: false,
        };
    },
    props: ["device"],
    created() {},
    methods: {
        intercomEnable() {
            this.intercom = !this.intercom;
        },
    },
};
</script>
<style scoped>
.apartments-card .card-body {
    padding: 5px;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
